<template>
    <div class="text-gray-400 bg-gray-900 min-h-screen z-10">
        <div class="fixed bg-gray-800 flex items-center pr-3 max-w-lg w-full">
            <div>
                <router-link to="/" href="#" class="p-3">
                    <i class="icon-arrow-left32"></i>
                </router-link>
            </div>
            <div class="py-3 ml-auto mr-3">Info Peringatan dini</div>
            <a @click="deleteNotif" href="#" class="p-3">
                <i class="icon-trash text-gray-500"></i>
            </a>
        </div>
        <div class="mt-16 px-3">
            <div v-if="notifs.length == 0" class="text-center text-gray-600">BELUM ADA NOTIFIKASI</div>
            <ul v-show="isLoading" class="divide-y divide-gray-800">
                <li class="py-3" v-for="a in 20" :key="a">
                    <div class="flex items-center justify-between">
                        <div class="h-3 bg-gray-800 w-16"></div>
                        <div class="h-3 bg-gray-800 w-32"></div>
                    </div>
                    <div class="h-3 w-full bg-gray-800 mt-4"></div>
                </li>
            </ul>
            <ul class="divide-y divide-gray-800">
                <li v-for="(notif, i) in notifs" :key="i" class="py-3">
                    <div class="flex items-center justify-between text-gray-500 mb-2">
                        <div class="font-medium">{{notif.data.parameter}} ({{notif.data.value}})</div>
                        <div>{{notif.data.added_at}}</div>
                    </div>
                    <div>
                       {{notif.data.description}}
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data () {
        return {
            isLoading: true,
            notifs: ''
        }
    },
    mounted () {
        this.getData()
        this.readNotif()
    },
    methods: {
        readNotif () {
            axios.post('/notifications/read')
            .then(() => {
                localStorage.setItem('notif','')
            })
        },
        getData () {
            axios.get('/notifications')
            .then((res) => {
                this.isLoading = false
                this.notifs = res.data.data
            })
        },
        deleteNotif () {
            if(this.notifs.length > 0)
            this.isLoading = true
            axios.post('/notifications/delete')
            .then(() => {
                this.isLoading = false
                this.notifs = ''
            })
        }
    }
}
</script>