<template>
    <div class="mb-20 text-gray-400">
        <div class="fixed w-full max-w-lg px-3 pt-4 bg-gray-800 z-20 shadow-md border-b border-gray-900">
            <div class="flex items-center justify-between mb-5">
                <div>
                    <button @click="onModalTanggal = true" class="border border-gray-700 px-2 py-1 bg-gray-800 rounded-sm">
                        {{label}}
                        <i class="icon-arrow-down22 ml-2"></i>
                    </button>
                </div>
                <div>
                    <button @click="onModalFilter = true" class="border border-gray-700 px-2 py-1 bg-gray-800 rounded-sm">
                        Interval
                        <i class="icon-arrow-down22 ml-2"></i>
                    </button>
                </div>
                <div>
                    <button @click="onModalParameter = true" class="border border-gray-700 px-2 py-1 bg-gray-800 rounded-sm">
                        Download
                        <i class="icon-file-download ml-2"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="overflow-hidden rounded-sm px-3 pt-20">
            <table class="w-full bg-gray-800">
                <thead>
                    <tr>
                        <th class="px-2 py-3 bg-gray-700">Tanggal</th>
                        <th class="px-2 py-3 bg-gray-700 text-center">Parameter</th>
                    </tr>
                </thead>
                <tbody v-if="isLoading" class="divide-y divide-gray-700 animate-pulse">
                    <tr v-for="i in 20" :key="i">
                        <td class="p-2">
                            <div class="h-3 w-16 bg-gray-700 mb-1"></div>
                            <div class="h-3 w-14 bg-green-900"></div>
                        </td>
                        <td class="p-2"> 
                            <div class="flex items-center justify-between mb-3">
                                <div class="h-3 w-12 bg-gray-700"></div>
                                <div class="h-3 w-12 bg-gray-700"></div>
                                <div class="h-3 w-12 bg-gray-700"></div>
                            </div>
                            <div class="flex items-center justify-between">
                                <div class="h-3 w-12 bg-gray-700"></div>
                                <div class="h-3 w-12 bg-gray-700"></div>
                                <div class="h-3 w-12 bg-gray-700"></div>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody v-if="reports" class="divide-y divide-gray-700">
                    <tr v-for="(report, i) in reports" :key="i">
                        <td class="text-xs p-2">
                            <div>{{report.added_at.substring(10,0)}}</div>
                            <div class="text-gray-500">{{report.added_at.substring(10,19)}}</div>
                        </td>
                        <td class="text-xs p-2">
                            <div class="flex items-center justify-between mb-2">
                                <div class="text-green-700">pH: {{report.ph}}</div>
                                <div class="text-red-700">Temp: {{report.tmp}}</div>
                                <div class="text-yellow-700">COD: {{report.cod}}</div>
                            </div>
                            <div class="flex items-center justify-between">
                                <div class="text-purple-700">TSS: {{report.tss}}</div>
                                <div class="text-blue-700">NH3N: {{report.nh3n}}</div>
                                <div class="text-cyan-700">Debit: {{report.debit}}</div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="text-right mt-5">
                <paginate :pages="page" @get-page="getData($event)"></paginate>
            </div>
        </div>
        <modal :show="onModalTanggal" @onClose="onCloseModalTanggal">
            <div class="bg-gray-800 w-full rounded-t-2xl">
                <div class="flex items-center p-2">
                    <button @click="onCloseModalTanggal" class="p-2"><i class="icon-cross2 pb-px"></i></button>
                    <div class="font-semibold text-lg">Pilih Tanggal</div>
                </div>
                <ul class="px-5 pt-5 divide-y divide-gray-700 boredr-b border-gray-800 mb-5">
                    <li class="py-3.5" @click="onFilter = true">
                        <label class="flex justify-between">
                            <div>Hari ini</div>
                            <div>
                                <input type="radio" name="tanggal" value="Hari ini" v-model="labelFilterTanggal">
                            </div>
                        </label>
                    </li>
                    <li class="py-3.5" @click="onFilter = true">
                        <label class="flex justify-between">
                            <div>Kemarin</div>
                            <div>
                                <input type="radio" name="tanggal" value="Kemarin" v-model="labelFilterTanggal">
                            </div>
                        </label>
                    </li>
                    <li class="py-3.5" @click="onFilter = true">
                        <label class="flex justify-between">
                            <div>7 Hari terakhir</div>
                            <div>
                                <input type="radio" name="tanggal" value="7 Hari terakhir" v-model="labelFilterTanggal">
                            </div>
                        </label>
                    </li>
                    <li class="py-3.5" @click="onFilter = true">
                        <label class="flex justify-between">
                            <div>30 Hari terakhir</div>
                            <div>
                                <input type="radio" name="tanggal" value="30 Hari terakhir" v-model="labelFilterTanggal">
                            </div>
                        </label>
                    </li>
                    <li class="py-3.5" @click="onFilter = true">
                        <label class="flex justify-between">
                            <div>Bulan ini</div>
                            <div>
                                <input type="radio" name="tanggal" value="Bulan ini" v-model="labelFilterTanggal">
                            </div>
                        </label>
                    </li>
                    <!-- <li class="py-3.5" @click="onFilter = true">
                        <label class="flex justify-between">
                            <div>Per Hari</div>
                            <div>
                                <input type="radio" name="tanggal">
                            </div>
                        </label>
                    </li>
                    <li class="py-3.5" @click="onFilter = true">
                        <label class="flex justify-between">
                            <div>Per Bulan</div>
                            <div>
                                <input type="radio" name="tanggal">
                            </div>
                        </label>
                    </li>
                    <li class="py-3.5" @click="onFilter = true">
                        <label class="flex justify-between">
                            <div>Per Tahun</div>
                            <div>
                                <input type="radio" name="tanggal">
                            </div>
                        </label>
                    </li> -->
                </ul>
                <div class="px-5 mb-5" v-show="onFilter">
                    <button @click="onGetFilterTanggal" class="py-2 bg-green-700 w-full rounded-sm">Terapkan</button>
                </div>
            </div>
        </modal>
        <modal :show="onModalFilter" @onClose="onCloseModalFilter">
            <div class="bg-gray-800 w-full rounded-t-2xl">
                <div class="flex items-center p-2">
                    <button @click="onCloseModalFilter" class="p-2"><i class="icon-cross2 pb-px"></i></button>
                    <div class="font-semibold text-lg">Pilih Interval</div>
                </div>
                <ul class="px-5 pt-5 divide-y divide-gray-700 border-gray-700 mb-5">
                    <li class="py-3.5" @click="onFilter = true">
                        <label class="flex justify-between">
                            <div>Per 2 Menit</div>
                            <div>
                                <input type="radio" name="filter" id="semua" value="2 menit" v-model="labelFilter">
                            </div>
                        </label>
                    </li>
                    <li class="py-3.5" @click="onFilter = true">
                        <label class="flex justify-between">
                            <div>Per 1 Jam</div>
                            <div>
                                <input type="radio" name="filter" id="minimal" value="1 jam" v-model="labelFilter">
                            </div>
                        </label>
                    </li>
                </ul>
                <div class="px-5 mb-5" v-show="onFilter">
                    <button @click="onGetFilter" class="py-2 bg-green-700 w-full rounded-sm">Terapkan</button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import Modal from '../components/Popup.vue'
import axios from 'axios'
import Paginate from '../components/Paginate.vue'
export default {
    components: {
        Modal,
        Paginate
    },
    data () {
        return {
            isLoading: false,
            onModalTanggal: false,
            onModalFilter: false,
            onFilter: false,
            labelFilterTanggal: 'Hari ini',
            label: 'Hari ini',
            reports: '',
            labelFilter: '2 menit',
            filter: '2 menit',
            page: '',
        }
    },
    mounted () {
        this.getData()
    },
    methods: {
        onCloseModalTanggal () {
            this.onFilter = false
            this.onModalTanggal = false
            this.labelFilterTanggal = this.label
        },
        onGetFilterTanggal () {
            this.label = this.labelFilterTanggal
            this.onModalTanggal = false
            this.onFilter = false
            this.$nextTick(() => {
                this.getData()
            })
        },
        onCloseModalFilter () {
            this.onFilter = false
            this.labelFilter = this.filter
            this.onModalFilter = false
        },
        onGetFilter () {
            this.onFilter = false
            this.filter = this.labelFilter
            this.onModalFilter = false
            this.$nextTick(() => {
                this.getData()
            })
        },
        getData (e) {
            document.documentElement.scrollTop = 0
            this.isLoading = true
            this.reports = ''
            axios.get('/report', {
                params: {
                    page: e,
                    label: this.label,
                    filter: this.filter
                }
            })
            .then((res) => {
                this.isLoading = false
                if(this.filter == '1 jam') {
                    this.reports = []
                    res.data.data.forEach(el => {
                        var debit = parseFloat(el.debit * 60).toFixed(2)
                        this.reports.push({ph: el.ph, tmp: el.tmp, cod: el.cod, tss: el.tss, nh3n: el.nh3n, debit: debit, added_at: el.jam+':00'})
                    });
                    this.page = res.data
                } else {
                    this.reports = res.data.data
                    this.page = res.data
                }
            })
        }
    }
}
</script>