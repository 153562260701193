<template>
    <div class="mb-20 text-gray-400">
        <div class="fixed w-full max-w-lg px-3 pt-4 bg-gray-800 z-20 shadow-md border-b border-gray-900">
            <div class="flex items-center justify-between mb-5">
                <div>
                    <button @click="onModalTanggal = true" class="border border-gray-700 px-2 py-1 bg-gray-800 rounded-sm">
                        {{label}}
                        <i class="icon-arrow-down22 ml-2"></i>
                    </button>
                </div>
                <div>
                    <button @click="onModalFilter = true" class="border border-gray-700 px-2 py-1 bg-gray-800 rounded-sm">
                        Filter
                        <i class="icon-arrow-down22 ml-2"></i>
                    </button>
                </div>
                <div>
                    <button @click="onModalParameter = true" class="border border-gray-700 px-2 py-1 bg-gray-800 rounded-sm">
                        Parameter
                        <i class="icon-arrow-down22 ml-2"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="overflow-hidden rounded-sm px-3 pt-20">
            <div class="flex items-center space-x-3 overflow-x-scroll no-scrollbar">
                <div v-show="parameters.ph" class="p-2 mb-3 border-l-2 border-green-500 bg-gray-800 rounded-sm flex items-center">
                    <div>pH</div>
                     <a @click="onDeleteParameter('pH')" href="#" class="ml-5">
                        <i class="icon-cross3 hover:text-red-500"></i>
                    </a>
                </div>
                <div v-show="parameters.tmp" class="p-2 mb-3 border-l-2 border-red-500 bg-gray-800 rounded-sm flex items-center">
                    <div>TEMP</div>
                     <a @click="onDeleteParameter('TMP')" href="#" class="ml-5">
                        <i class="icon-cross3 hover:text-red-500"></i>
                    </a>
                </div>
                <div v-show="parameters.cod" class="p-2 mb-3 border-l-2 border-yellow-500 bg-gray-800 rounded-sm flex items-center">
                    <div>COD</div>
                     <a @click="onDeleteParameter('COD')" href="#" class="ml-5">
                        <i class="icon-cross3 hover:text-red-500"></i>
                    </a>
                </div>
                <div v-show="parameters.tss" class="p-2 mb-3 border-l-2 border-purple-500 bg-gray-800 rounded-sm flex items-center">
                    <div>TSS</div>
                     <a @click="onDeleteParameter('TSS')" href="#" class="ml-5">
                        <i class="icon-cross3 hover:text-red-500"></i>
                    </a>
                </div>
                <div v-show="parameters.nh3n" class="p-2 mb-3 border-l-2 border-blue-500 bg-gray-800 rounded-sm flex items-center">
                    <div>NH3N</div>
                     <a @click="onDeleteParameter('NH3N')" href="#" class="ml-5">
                        <i class="icon-cross3 hover:text-red-500"></i>
                    </a>
                </div>
                <div v-show="parameters.debit" class="p-2 mb-3 border-l-2 border-cyan-500 bg-gray-800 rounded-sm flex items-center">
                    <div>DEBIT</div>
                     <a @click="onDeleteParameter('DEBIT')" href="#" class="ml-5">
                        <i class="icon-cross3 hover:text-red-500"></i>
                    </a>
                </div>
            </div>
            <table class="w-full bg-gray-800">
                <thead>
                    <tr>
                        <th class="px-2 py-3 bg-gray-700">Tanggal</th>
                        <th class="px-2 py-3 bg-gray-700">Parameter</th>
                        <th class="px-2 py-3 bg-gray-700">Nilai</th>
                    </tr>
                </thead>
                <tbody v-if="isLoading" class="divide-y divide-gray-700 animate-pulse">
                    <tr v-for="i in 20" :key="i">
                        <td class="p-2">
                            <div class="h-3 w-32 bg-gray-700 mb-3"></div>
                            <div class="h-3 w-40 bg-gray-700"></div>
                        </td>
                        <td class="p-2">
                            <div class="h-3 w-10 bg-gray-700"></div>
                        </td>
                        <td class="p-2">
                            <div class="h-3 w-5 bg-gray-700"></div>
                        </td>
                    </tr>
                </tbody>
                <tbody v-if="warnings" class="divide-y divide-gray-700">
                    <tr v-for="(warning, i) in warnings" :key="i">
                        <td class="text-xs p-2" :class="{'text-red-500': warning.threshold == 'max'}">
                            <div class="mb-2">
                                <i class="icon-watch2 mr-1"></i>
                                <span>{{warning.added_at}}</span>
                            </div>
                            <div>{{warning.description}}</div>
                        </td>
                        <td class="p-2" :class="{'text-red-500': warning.threshold == 'max'}">{{warning.parameter}}</td>
                        <td class="text-right p-2" :class="{'text-red-500': warning.threshold == 'max'}">{{warning.value}}</td>
                    </tr>
                </tbody>
            </table>
            <div class="text-right mt-5">
                <paginate :pages="page" @get-page="getData($event)"></paginate>
            </div>
        </div>
        <modal :show="onModalTanggal" @onClose="onCloseModalTanggal">
            <div class="bg-gray-800 w-full rounded-t-2xl">
                <div class="flex items-center p-2">
                    <button @click="onCloseModalTanggal" class="p-2"><i class="icon-cross2 pb-px"></i></button>
                    <div class="font-semibold text-lg">Pilih Tanggal</div>
                </div>
                <ul class="px-5 pt-5 divide-y divide-gray-700 boredr-b border-gray-800 mb-5">
                    <li class="py-3.5" @click="onFilter = true">
                        <label class="flex justify-between">
                            <div>Hari ini</div>
                            <div>
                                <input type="radio" name="tanggal" value="Hari ini" v-model="labelFilterTanggal">
                            </div>
                        </label>
                    </li>
                    <li class="py-3.5" @click="onFilter = true">
                        <label class="flex justify-between">
                            <div>Kemarin</div>
                            <div>
                                <input type="radio" name="tanggal" value="Kemarin" v-model="labelFilterTanggal">
                            </div>
                        </label>
                    </li>
                    <li class="py-3.5" @click="onFilter = true">
                        <label class="flex justify-between">
                            <div>7 Hari terakhir</div>
                            <div>
                                <input type="radio" name="tanggal" value="7 Hari terakhir" v-model="labelFilterTanggal">
                            </div>
                        </label>
                    </li>
                    <li class="py-3.5" @click="onFilter = true">
                        <label class="flex justify-between">
                            <div>30 Hari terakhir</div>
                            <div>
                                <input type="radio" name="tanggal" value="30 Hari terakhir" v-model="labelFilterTanggal">
                            </div>
                        </label>
                    </li>
                    <li class="py-3.5" @click="onFilter = true">
                        <label class="flex justify-between">
                            <div>Bulan ini</div>
                            <div>
                                <input type="radio" name="tanggal" value="Bulan ini" v-model="labelFilterTanggal">
                            </div>
                        </label>
                    </li>
                    <!-- <li class="py-3.5" @click="onFilter = true">
                        <label class="flex justify-between">
                            <div>Per Hari</div>
                            <div>
                                <input type="radio" name="tanggal">
                            </div>
                        </label>
                    </li>
                    <li class="py-3.5" @click="onFilter = true">
                        <label class="flex justify-between">
                            <div>Per Bulan</div>
                            <div>
                                <input type="radio" name="tanggal">
                            </div>
                        </label>
                    </li>
                    <li class="py-3.5" @click="onFilter = true">
                        <label class="flex justify-between">
                            <div>Per Tahun</div>
                            <div>
                                <input type="radio" name="tanggal">
                            </div>
                        </label>
                    </li> -->
                </ul>
                <div class="px-5 mb-5" v-show="onFilter">
                    <button @click="onGetFilterTanggal" class="py-2 bg-green-700 w-full rounded-sm">Terapkan</button>
                </div>
            </div>
        </modal>
        <modal :show="onModalFilter" @onClose="onCloseModalFilter">
            <div class="bg-gray-800 w-full rounded-t-2xl">
                <div class="flex items-center p-2">
                    <button @click="onCloseModalFilter" class="p-2"><i class="icon-cross2 pb-px"></i></button>
                    <div class="font-semibold text-lg">Pilih Filter</div>
                </div>
                <ul class="px-5 pt-5 divide-y divide-gray-700 border-b border-gray-700 mb-5">
                    <li class="py-3.5" @click="onFilter = true">
                        <label class="flex justify-between">
                            <div>Semu Ambang Batas</div>
                            <div>
                                <input type="radio" name="filter" value="semua" v-model="labelFilter">
                            </div>
                        </label>
                    </li>
                    <li class="py-3.5" @click="onFilter = true">
                        <label class="flex justify-between">
                            <div>Ambang Batas Minimal</div>
                            <div>
                                <input type="radio" name="filter" value="min" v-model="labelFilter">
                            </div>
                        </label>
                    </li>
                    <li class="py-3.5" @click="onFilter = true">
                        <label class="flex justify-between">
                            <div>Ambang Batas Maksimal</div>
                            <div>
                                <input type="radio" name="filter" id="maximal" value="max" v-model="labelFilter">
                            </div>
                        </label>
                    </li>
                </ul>
                <div class="px-5 mb-5" v-show="onFilter">
                    <button @click="onGetFilter" class="py-2 bg-green-700 w-full rounded-sm">Terapkan</button>
                </div>
            </div>
        </modal>
        <modal :show="onModalParameter" @onClose="onCloseModalParameter">
            <div class="bg-gray-800 w-full rounded-t-2xl">
                <div class="flex items-center p-2">
                    <button @click="onCloseModalParameter" class="p-2"><i class="icon-cross2 pb-px"></i></button>
                    <div class="font-semibold text-lg">Pilih Parameter Sensor</div>
                </div>
                <ul class="px-5 pt-5 divide-y divide-gray-700 border-b border-gray-700 mb-5">
                    <li class="py-3.5" @click="onFilter = true">
                        <label class="flex justify-between">
                            <div>pH</div>
                            <div>
                                <input type="checkbox" value="pH" v-model="filterParameter.ph">
                            </div>
                        </label>
                    </li>
                    <li class="py-3.5" @click="onFilter = true">
                        <label class="flex justify-between">
                            <div>Temperatur</div>
                            <div>
                                <input type="checkbox" value="TMP" v-model="filterParameter.tmp" >
                            </div>
                        </label>
                    </li>
                    <li class="py-3.5" @click="onFilter = true">
                        <label class="flex justify-between">
                            <div>COD</div>
                            <div>
                                <input type="checkbox" value="COD" v-model="filterParameter.cod">
                            </div>
                        </label>
                    </li>
                    <li class="py-3.5" @click="onFilter = true">
                        <label class="flex justify-between">
                            <div>TSS</div>
                            <div>
                                <input type="checkbox" value="TSS" v-model="filterParameter.tss">
                            </div>
                        </label>
                    </li>
                    <li class="py-3.5" @click="onFilter = true">
                        <label class="flex justify-between">
                            <div>NH3N</div>
                            <div>
                                <input type="checkbox" value="NH3N" v-model="filterParameter.nh3n">
                            </div>
                        </label>
                    </li>
                    <li class="py-3.5" @click="onFilter = true">
                        <label class="flex justify-between">
                            <div>DEBIT</div>
                            <div>
                                <input type="checkbox" value="DEBIT" v-model="filterParameter.debit">
                            </div>
                        </label>
                    </li>
                </ul>
                <div class="px-5 mb-5" v-show="onFilter">
                    <button @click="onGetFilterParameter" class="py-2 bg-green-700 w-full rounded-sm">Terapkan</button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import Modal from '../components/Popup.vue'
import Paginate from '../components/Paginate.vue'
import axios from 'axios'
export default {
    components: {
        Modal,
        Paginate,
    },
    data () {
        return {
            isLoading: true,
            onModalTanggal: false,
            labelFilterTanggal: 'Hari ini',
            label: 'Hari ini',
            onModalFilter: false,
            labelFilter: 'semua',
            filter: 'semua',
            onModalParameter: false,
            onFilter: false,
            parameters: {
                ph: '',
                tmp: '',
                cod: '',
                tss: '',
                nh3n: '',
                debit: ''
            },
            filterParameter: {
                ph: '',
                tmp: '',
                cod: '',
                tss: '',
                nh3n: '',
                debit: ''
            },
            warnings: '',
            sortKey: 'added_at',
            sortOrder: 'desc',
            page: '',
        }
    },
    mounted () {
        this.getData()
    },
    methods: {
        onCloseModalTanggal () {
            this.onFilter = false
            this.onModalTanggal = false
            this.labelFilterTanggal = this.label
        },
        onGetFilterTanggal () {
            this.label = this.labelFilterTanggal
            this.onModalTanggal = false
            this.onFilter = false
            this.$nextTick(() => {
                this.getData()
            })
        },
        onCloseModalFilter () {
            this.onFilter = false,
            this.onModalFilter = false,
            this.labelFilter = this.filter
        },
        onGetFilter () {
            this.filter = this.labelFilter
            this.onModalFilter = false
            this.onFilter = false
            this.$nextTick(() => {
                this.getData()
            })
        },
        onCloseModalParameter () {
            this.onFilter = false
            this.onModalParameter = false
            if(this.parameters.ph) {
                this.filterParameter.ph = true
            } else {
                this.filterParameter.ph = ''
            }
            if(this.parameters.tmp) {
                this.filterParameter.tmp = true
            } else {
                this.filterParameter.tmp = ''
            }
            if(this.parameters.cod) {
                this.filterParameter.cod = true
            } else {
                this.filterParameter.cod = ''
            }
            if(this.parameters.tss) {
                this.filterParameter.tss = true
            } else {
                this.filterParameter.tss = ''
            }
            if(this.parameters.nh3n) {
                this.filterParameter.nh3n = true
            } else {
                this.filterParameter.nh3n = ''
            }
            if(this.parameters.debit) {
                this.filterParameter.debit = true
            } else {
                this.filterParameter.debit = ''
            }
        },
        onGetFilterParameter () {
            if(this.filterParameter.ph) {
                this.parameters.ph = 'pH'
            } else {
                this.parameters.ph = ''
            }
            if(this.filterParameter.tmp) {
                this.parameters.tmp = 'TMP'
            } else {
                this.parameters.tmp = ''
            }
            if(this.filterParameter.cod) {
                this.parameters.cod = 'COD'
            } else {
                this.parameters.cod = ''
            }
            if(this.filterParameter.tss) {
                this.parameters.tss = 'TSS'
            } else {
                this.parameters.tss = ''
            }
            if(this.filterParameter.nh3n) {
                this.parameters.nh3n = 'NH3N'
            } else {
                this.parameters.nh3n = ''
            }
            if(this.filterParameter.debit) {
                this.parameters.debit = 'DEBIT'
            } else {
                this.parameters.debit = ''
            }
            this.onModalParameter = false
            this.onFilter = false
            this.$nextTick(() => {
                this.getData()
            })
        },
        onDeleteParameter(val) {
            switch(val) {
                case "pH":
                    this.parameters.ph = ''
                    this.filterParameter.ph = ''
                    break
                case "TMP":
                    this.parameters.tmp = ''
                    this.filterParameter.tmp = ''
                    break
                case "COD":
                    this.parameters.cod = ''
                    this.filterParameter.cod = ''
                    break
                case "TSS":
                    this.parameters.tss = ''
                    this.filterParameter.tss = ''
                    break
                case "NH3N":
                    this.parameters.nh3n = ''
                    this.filterParameter.nh3n = ''
                    break
                case "DEBIT":
                    this.parameters.debit = ''
                    this.filterParameter.debit = ''
            }
            this.$nextTick(() => {
                this.getData()
            })
        },
        getData (e) {
            document.documentElement.scrollTop = 0
            this.isLoading = true
            this.warnings = ''
            axios.get('warning', {
                params: {
                    page: e,
                    label: this.label,
                    filter: this.filter,
                    parameters: this.parameters,
                    sortkey: this.sortKey,
                    sortorder: this.sortOrder
                }
            })
            .then((res) => {
                this.isLoading = false
                this.page = res.data
                this.warnings = res.data.data
            })
        }
    }
}
</script>
<style scoped>
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
</style>