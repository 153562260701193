import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Notif from '../views/Notif.vue'
import Statistik from '../views/Statistik.vue'
import Peringatan from '../views/Peringatan.vue'
import Laporan from '../views/Laporan.vue'
import Akun from '../views/Akun.vue'
import Login from '../views/Login.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/notif',
    name: 'Notif',
    component: Notif
  },
  {
    path: '/statistik',
    name: 'Statistik',
    component: Statistik
  },
  {
    path: '/peringatan',
    name: 'Peringatan',
    component: Peringatan
  },
  {
    path: '/laporan',
    name: 'Laporan',
    component: Laporan
  },
  {
    path: '/akun',
    name: 'Akun',
    component: Akun
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  }
]

const router = new VueRouter({
  scrollBehavior() {
    return {x:0, y:0}
  },
  linkActiveClass: 'active',
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

function isAuthenticated () {
  return localStorage.getItem('token')
}
router.beforeEach((to, from, next) => {
  if(to.name !== 'Login' && !isAuthenticated()) next({name: 'Login'})
  else if (to.name == 'Login' && isAuthenticated()) next({name: 'Home'})
  else next()
})

export default router
