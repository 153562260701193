<template>
    <div class="flex items-center justify-between">
        <div>Halaman: {{current_page}} dari {{pages.last_page}}</div>
        <ul v-if="pages.last_page > 1" class="inline-flex rounded-sm border border-gray-700 divide-x divide-gray-700 text-gray-300 bg-gray-800">
            
            <li class="h-9 hover:bg-gray-700"><button @click.prevent="$emit('get-page', pages.current_page-1)" :class="[pages.current_page < 2? 'disabled text-gray-600':'']" class="flex items-center h-full px-3.5" :disabled="pages.current_page < 2"><i class="icon-arrow-left12"></i></button></li>
            
            <li class="h-9 hover:bg-gray-700"><button @click.prevent="$emit('get-page', pages.current_page+1)" href="#" :class="[pages.current_page == pages.last_page ? 'disabled text-gray-600':'']" class="flex items-center h-full px-3.5" :disabled="pages.current_page == pages.last_page"><i class="icon-arrow-right13"></i></button></li>
        </ul>
    </div>
</template>

<script>
export default {
    props: ['pages'],
    data () {
        return {
            rangeOne: [],
            rangeTwo: [],
            rangeThree: [] 
        }
    },
    computed: {
        current_page () {
            return this.pages.current_page
        },
        total () {
            return this.pages.total
        }
    },
    
    watch: {
        current_page (val) {
            this.rangeOne =[]
            this.rangeTwo = []
            this.rangeThree = []
            if(this.pages.last_page  <= 10 ) {
                for (let i = 0; i < this.pages.last_page ; i++) {
                    this.rangeOne.push({page: i+1})                    
                }
            }else if(this.pages.last_page  > 10 && val < 7){
                for (let i = 0; i < 8; i++) {
                    this.rangeOne.push({page: i+1})                    
                }
                for (let i = 0; i < 2; i++) {
                    this.rangeThree.push({page: this.pages.last_page  + i -1})
                }
            }else if(val <= this.pages.last_page  - 6 && val > 6){
                for (let i = 0; i < 2; i++) {
                    this.rangeOne.push({page: i+1})                    
                }
                for (let i = 0; i < 7; i++) {
                    this.rangeTwo.push({page: val+i -3})                    
                }
                for (let i = 0; i < 2; i++) {
                    this.rangeThree.push({page: this.pages.last_page  + i -1})
                }
            }else{
                for (let i = 0; i < 2; i++) {
                    this.rangeOne.push({page: i+1})                    
                }
                for (let i = 0; i < 9; i++) {
                    this.rangeThree.push({page: this.pages.last_page  +i -8})
                }
            }
        },
        total () {
            var val = this.pages.current_page
            this.rangeOne =[]
            this.rangeTwo = []
            this.rangeThree = []
            if(this.pages.last_page  <= 10 ) {
                for (let i = 0; i < this.pages.last_page ; i++) {
                    this.rangeOne.push({page: i+1})                    
                }
            }else if(this.pages.last_page  > 10 && val < 7){
                for (let i = 0; i < 8; i++) {
                    this.rangeOne.push({page: i+1})                    
                }
                for (let i = 0; i < 2; i++) {
                    this.rangeThree.push({page: this.pages.last_page  + i -1})
                }
            }else if(val <= this.pages.last_page  - 6 && val > 6){
                for (let i = 0; i < 2; i++) {
                    this.rangeOne.push({page: i+1})                    
                }
                for (let i = 0; i < 7; i++) {
                    this.rangeTwo.push({page: val+i -3})                    
                }
                for (let i = 0; i < 2; i++) {
                    this.rangeThree.push({page: this.pages.last_page  + i -1})
                }
            }else{
                for (let i = 0; i < 2; i++) {
                    this.rangeOne.push({page: i+1})                    
                }
                for (let i = 0; i < 9; i++) {
                    this.rangeThree.push({page: this.pages.last_page  +i -8})
                }
            }

        },
    }
}

</script>

<style  scoped>
    .disabled:hover {
        cursor: no-drop;
    }
</style>