<template>
    <div class="mb-20">
		<loading :active.sync="isLoading" 
            :is-full-page="true"
			:color="'#eff3f6'"
			:background-color="'#1f2937'">
        </loading>
		<div class="flex items-center justify-around mt-3 px-3 text-gray-400 border-b border-gray-800 pb-3">
			<div @click="onFilter('1H')" :class="{'bg-blue-700 rounded-sm text-gray-300': filter=='1H'}" class="py-1 px-2">1H</div>
			<div @click="onFilter('1M')" :class="{'bg-blue-700 rounded-sm' : filter=='1M'}" class="py-1 px-2">1M</div>
			<div @click="onFilter('1B')" :class="{'bg-blue-700 rounded-sm' : filter=='1B'}" class="py-1 px-2">1B</div>
			<div @click="onFilter('3B')" :class="{'bg-blue-700 rounded-sm' : filter=='3B'}" class="py-1 px-2">3B</div>
			<div @click="onFilter('1T')" :class="{'bg-blue-700 rounded-sm' : filter=='1T'}" class="py-1 px-2">1T</div>
		</div>
        <div class="px-3 pt-4 text-gray-400 rounded-sm">
            <div class="bg-gray-800 p-3">
                <canvas id="debit"></canvas>
            </div>
        </div>
        <div class="px-3 pt-4 text-gray-400 rounded-sm">
            <div class="bg-gray-800 p-3">
                <canvas id="ph"></canvas>
            </div>
        </div>
        <div class="px-3 pt-4 text-gray-400 rounded-sm">
            <div class="bg-gray-800 p-3">
                <canvas id="tmp"></canvas>
            </div>
        </div>
        <div class="px-3 pt-4 text-gray-400 rounded-sm">
            <div class="bg-gray-800 p-3">
                <canvas id="cod"></canvas>
            </div>
        </div>
        <div class="px-3 pt-4 text-gray-400 rounded-sm">
            <div class="bg-gray-800 p-3">
                <canvas id="tss"></canvas>
            </div>
        </div>
        <div class="px-3 pt-4 text-gray-400 rounded-sm">
            <div class="bg-gray-800 p-3">
                <canvas id="nh3n"></canvas>
            </div>
        </div>
    </div>
</template>

<script>
import Chart from 'chart.js/auto'
import axios from 'axios'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
	components: {
		Loading
	},
    data () {
        return {
			isLoading: false,
            ph: [],
            tmp: [],
            cod: [],
            tss: [],
            nh3n: [],
            debit: [],
            time: [],
			filter: '1M'
        }
    },
    mounted () {
        let app = this
		const tooltipLine = {
			id: 'tooltipLine',
			beforeDraw: chart => {
				if(chart.tooltip._active && chart.tooltip._active.length) {
					const ctx = chart.ctx
					ctx.save()
					const activePoint = chart.tooltip._active[0]
					ctx.beginPath()
					
					ctx.moveTo(activePoint.element.x, chart.chartArea.top)
					ctx.lineTo(activePoint.element.x, chart.chartArea.bottom)
					ctx.lineWidth = 1.5
					ctx.strokeStyle = 'red'
					ctx.stroke()
					ctx.restore()
				}
			}
		}
        var ctxDebit = document.getElementById('debit')
        window.myDebit =  new Chart(ctxDebit, {
            type: 'line',
            data: {
                labels: app.time,
				datasets: [{
                    label: 'DEBIT',
					data: app.debit,
					borderColor: 'rgb(5,182,212, 0.7)',
					borderWidth: '1',
					pointRadius: '0',
					pointHoverRadius: '0',
					spanGaps: true
				}]
            },
            options: {
                responsive: true,
				interaction: {
					intersect: false,
					mode: 'index',
				},
                plugins: {
                    legend: {
                        labels: {
                            color: 'white'
                        },
                        position: 'top',
                    },
                },
                scales: {
					x: {
						labels: false,
						ticks: {
							color: 'rgb(156,163,168)',
							display: false,
                            font: {
                                size: 9
                            }
						},
						grid: {
							color: 'rgb(55,65,81)',
							display: false
						}
					},
					y: {
						grace: '1%',
						ticks: {
							color: 'rgb(156,163,168)',
							font: {
								size: 10
							}
						},
						grid: {
							color: 'rgb(55,65,81)',
							display: true
						}
					}
				}
            },
			plugins: [tooltipLine]
        })

        var ctxPh = document.getElementById('ph')
        window.myPh = new Chart(ctxPh, {
            type: 'line',
            data: {
                labels: app.time,
				datasets: [{
                    label: 'pH METER',
					data: app.ph,
					borderColor: 'rgb(16,185,140, 0.7)',
					borderWidth: '1',
					pointRadius: '0',
					pointHoverRadius: '0'
				}]
            },
            options: {
                responsive: true,
				interaction: {
					intersect: false,
					mode: 'index',
				},
                plugins: {
                    legend: {
                        labels: {
                            color: 'white'
                        },
                        position: 'top',
                    },
                },
                scales: {
					x: {
						labels: false,
						ticks: {
							color: 'rgb(156,163,168)',
							display: false
						},
						grid: {
							color: 'rgb(55,65,81)',
							display: false
						}
					},
					y: {
						grace: '1%',
						ticks: {
							color: 'rgb(156,163,168)',
							font: {
								size: 10
							}
						},
						grid: {
							color: 'rgb(55,65,81)',
							display: true
						}
					}
				}
            },
			plugins: [tooltipLine]
        })

        var ctxTmp = document.getElementById('tmp')
        window.myTmp = new Chart(ctxTmp, {
            type: 'line',
            data: {
                labels: app.time,
				datasets: [{
                    label: 'TEMPERATUR',
					data: app.tmp,
					borderColor: 'rgb(239,68,102, 0.7)',
					borderWidth: '1',
					pointRadius: '0',
					pointHoverRadius: '0'
				}]
            },
            options: {
                responsive: true,
				interaction: {
					intersect: false,
					mode: 'index',
				},
                plugins: {
                    legend: {
                        labels: {
                            color: 'white'
                        },
                        position: 'top',
                    },
                },
                scales: {
					x: {
						labels: false,
						ticks: {
							color: 'rgb(156,163,168)',
							display: false
						},
						grid: {
							color: 'rgb(55,65,81)',
							display: false
						}
					},
					y: {
						grace: '1%',
						ticks: {
							color: 'rgb(156,163,168)',
							font: {
								size: 10
							}
						},
						grid: {
							color: 'rgb(55,65,81)',
							display: true
						}
					}
				}
            },
			plugins: [tooltipLine]
        })

        var ctxCod = document.getElementById('cod')
        window.myCod = new Chart(ctxCod, {
            type: 'line',
            data: {
                labels: app.time,
				datasets: [{
                    label: 'COD',
					data: app.cod,
					borderColor: 'rgb(245,158,10, 0.7)',
					borderWidth: '1',
					pointRadius: '0',
					pointHoverRadius: '0'
				}]
            },
            options: {
                responsive: true,
				interaction: {
					intersect: false,
					mode: 'index',
				},
                plugins: {
                    legend: {
                        labels: {
                            color: 'white'
                        },
                        position: 'top',
                    },
                },
                scales: {
					x: {
						labels: false,
						ticks: {
							color: 'rgb(156,163,168)',
							display: false
						},
						grid: {
							color: 'rgb(55,65,81)',
							display: false
						}
					},
					y: {
						grace: '1%',
						ticks: {
							color: 'rgb(156,163,168)',
							font: {
								size: 10
							}
						},
						grid: {
							color: 'rgb(55,65,81)',
							display: true
						}
					}
				}
            },
			plugins: [tooltipLine]
        })

        var ctxTss = document.getElementById('tss')
        window.myTss = new Chart(ctxTss, {
            type: 'line',
            data: {
                labels: app.time,
				datasets: [{
                    label: 'TSS',
					data: app.tss,
					borderColor: 'rgb(168,85,247, 0.7)',
					borderWidth: '1',
					pointRadius: '0',
					pointHoverRadius: '0'
				}]
            },
            options: {
                responsive: true,
				interaction: {
					intersect: false,
					mode: 'index',
				},
                plugins: {
                    legend: {
                        labels: {
                            color: 'white'
                        },
                        position: 'top',
                    },
                },
                scales: {
					x: {
						labels: false,
						ticks: {
							color: 'rgb(156,163,168)',
							display: false
						},
						grid: {
							color: 'rgb(55,65,81)',
							display: false
						}
					},
					y: {
						ticks: {
							color: 'rgb(156,163,168)',
							font: {
								size: 10
							}
						},
						grid: {
							color: 'rgb(55,65,81)',
							display: true
						}
					}
				}
            },
			plugins: [tooltipLine]
        })

        var ctxNh3n = document.getElementById('nh3n')
        window.myNh3n = new Chart(ctxNh3n, {
            type: 'line',
            data: {
                labels: app.time,
				datasets: [{
                    label: 'NH3N',
					data: app.nh3n,
					borderColor: 'rgb(58,133,247, 0.7)',
					borderWidth: '1',
					pointRadius: '0',
					pointHoverRadius: '0'
				}]
            },
            options: {
                responsive: true,
				interaction: {
					intersect: false,
					mode: 'index',
				},
                plugins: {
                    legend: {
                        labels: {
                            color: 'white'
                        },
                        position: 'top',
                    },
                },
                scales: {
					x: {
						labels: false,
						ticks: {
							color: 'rgb(156,163,168)',
							display: false
						},
						grid: {
							color: 'rgb(55,65,81)',
							display: false
						}
					},
					y: {
						grace: '1%',
						ticks: {
							color: 'rgb(156,163,168)',
							font: {
								size: 10
							}
						},
						grid: {
							color: 'rgb(55,65,81)',
							display: true
						}
					}
				}
            },
			plugins: [tooltipLine]
        })
		this.getData()
    },
	methods: {
		onFilter (val) {
			this.filter = val
			this.$nextTick(() => {
				this.getData()
			})
			this.ph.splice(0, this.ph.length)
			this.tmp.splice(0, this.tmp.length)
			this.cod.splice(0, this.cod.length)
			this.tss.splice(0, this.tss.length)
			this.nh3n.splice(0, this.nh3n.length)
			this.debit.splice(0, this.debit.length)
			this.time.splice(0, this.time.length)
			window.myDebit.update()
			window.myPh.update()
			window.myTmp.update()
			window.myCod.update()
			window.myTss.update()
			window.myNh3n.update()
		},
	
		getData () {
			this.isLoading = true
			axios.get('statistics/mobile', {
				params: {
					filter: this.filter
				}
			})
			.then((res) => {
				this.isLoading = false
				let modbus = res.data.reverse()
				if(this.filter == '1H') {
					modbus.forEach(el => {
						let date = new Date(el.added_at)
						let day = date.getDate()
						let month = date.getMonth()
						let year = date.getFullYear()
						let h = date.getHours()
						let m = date.getMinutes()
						let s = date.getSeconds()
						this.time.push(`${year}/${month+1}/${day} ${h}:${m}:${s}`)
						this.ph.push(el.ph)
						this.tmp.push(el.tmp)
						this.cod.push(el.cod)
						this.tss.push(el.tss)
						this.nh3n.push(el.nh3n)
						this.debit.push(el.debit)
					});
					this.$nextTick(() => {
						window.myDebit.update()
						window.myPh.update()
						window.myTmp.update()
						window.myCod.update()
						window.myTss.update()
						window.myNh3n.update()
					})
				}
				if(this.filter == '1M' || this.filter == '1B' || this.filter == '3B') {
					modbus.forEach(el => {
						this.time.push(el.jam)
						this.ph.push(el.ph)
						this.tmp.push(el.tmp)
						this.cod.push(el.cod)
						this.tss.push(el.tss)
						this.nh3n.push(el.nh3n)
						this.debit.push(el.debit)
					});
					this.$nextTick(() => {
						window.myDebit.update()
						window.myPh.update()
						window.myTmp.update()
						window.myCod.update()
						window.myTss.update()
						window.myNh3n.update()
					})
				}
				if(this.filter == '1T') {
					modbus.forEach(el => {
						this.time.push(el.tanggal)
						this.ph.push(el.ph)
						this.tmp.push(el.tmp)
						this.cod.push(el.cod)
						this.tss.push(el.tss)
						this.nh3n.push(el.nh3n)
						this.debit.push(el.debit)
					});
					this.$nextTick(() => {
						window.myDebit.update()
						window.myPh.update()
						window.myTmp.update()
						window.myCod.update()
						window.myTss.update()
						window.myNh3n.update()
					})
				}
				
			})
		}
	}
}
</script>